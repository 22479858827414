import { db, auth } from "@/helpers/firebase";
import { getDocs, collection, where, query } from "firebase/firestore";

class EventsDS {
  async read() {
    try {
      const q = query(collection(db, "Events"), where("confirmed", "==", true));
      const events = await getDocs(q);
      return events;
    } catch {
      return undefined;
    }
  }

  async reserved() {
    if (auth.currentUser) {
      const usersRef = collection(db, "Users");
      const ts = Date.now(); //firebase.firestore.FieldValue.Timestamp.now();
      const q = query(
        collection(usersRef, auth.currentUser.uid, "events"),
        where("startdate", ">", ts)
      );
      const events = await getDocs(q);
      return events;
    } else {
      return null;
    }
  }
}

export default new EventsDS();
