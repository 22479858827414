<template>
  <div class="summary-work" v-if="value">
    <div class="summary-work__link-image">
      <a :href="value.url" target="_blank">
        <figure class="summary-work__figure">
          <img
            ref="workImg"
            id="workImg"
            :src="value.image"
            alt=""
          />
        </figure>
      </a>
    </div>
    <div class="summary-work__header">
      <a :href="value.url" target="_blank" class="flex-grow-1">
        <h2 class="summary-work__title">
          <div>{{ value.name }}</div>
          <a :href="value.organizer.url"  target="_blank"><strong>{{ value.organizer.name }}</strong></a>
        </h2>
      </a>
      <div>
        <a :href="value.location.gmaps" target="_blank">
          <v-icon color="base">mdi-map-marker</v-icon>
        </a>
      </div>
    </div>
    <div class="summary-work__content">
      <div class="summary-work__data">
        <div>
          <div>{{ $t("fee") }}</div>
          <div>
            <strong>{{
              value.entrance.public>0 ? formatAmount(value.entrance.currency, value.entrance.public) : $store.state.lang === 'ja' ? 'なし' : 'No'
            }}</strong>
          </div>
        </div>
        <div v-if="value.entrance.public !== value.entrance.noa">
          <div>{{ $t("guestlist") }}</div>
          <div>
            <strong>{{
              formatAmount(value.entrance.currency, value.entrance.noa)
            }}</strong>
          </div>
        </div>
        <div v-else>
          <div>{{ $t("guestlist") }}</div>
          <div>
            <strong>{{$store.state.lang === 'ja' ? 'なし' : 'No'}}</strong>
          </div>
        </div>
        <div>
          <div>{{ $t("drinks") }}</div>
          <div v-if="value.entrance.drinks>0">
            <strong>{{ value.entrance.drinks }} {{$store.state.lang === 'ja' ? '杯付き' : ''}}</strong>
          </div>
          <div v-else>
            <strong>{{$store.state.lang === 'ja' ? 'なし' : 'No'}}</strong>
          </div>
        </div>
        <div>
          <div>{{ $t("theme") }}</div>
          <div>
            <strong>{{value.description[$store.state.lang]}}</strong>
          </div>
        </div>
        <div class="summary-work__footer">
          <div class="summary-work__graph">
            <div>
              <div>{{ $t("date") }}</div>
              <div>
                <strong>{{ toDate(value.startdate) }}</strong>
              </div>
            </div>
          </div>
          <div class="summary-work__graph">
            <div>
              <div>{{ $t("doorsOpen") }}</div>
              <div>
                <strong>{{ toTime(value.startdate) }}</strong>
              </div>
            </div>
          </div>
          <div class="summary-work__actions">
            <!-- <tippy
              v-if="!$store.state.user"
              arrow
              arrow-type="round"
              theme="dark"
            >
              <template v-slot:trigger>
                <v-btn disabled color="primary">
                  {{ $t("reserve") }}
                </v-btn>
              </template>
              {{ $t("onlyNoaUsers") }}
            </tippy> -->
            <v-btn v-if="value.startdate > Date.now()" :color="value.reserved ? 'warning' : 'primary'" @click="reserve">
              {{ value.reserved ? $t("unregister") : value.guestlist ? $t("reserve") : $t("attend") }}
            </v-btn>
            <!-- <div v-else>
              <p>blablabla</p>
            </div> -->
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorsAndIcons from "@/mixins/colorsAndIcons.js";
import helpers from "@/mixins/helpers.js";
// import Certificate from "@/components/Templates/Certificate";

export default {
  name: "EventItem",
  components: {},
  mixins: [colorsAndIcons, helpers],
  props: {
    value: Object,
    isPublic: { type: Boolean, default: true },
  },
  data: () => {
    return {
      framesModal: false,
      showCertificate: false,
    };
  },
  computed: {
    ownership() {
      return (
        Math.round(
          1000 * ((100 * this.value.quantity) / this.value.totalFrames)
        ) / 1000
      );
    },
    actionItems() {
      return [
        {
          title: this.$t("certificate"),
          icon: "mdi-file-download",
          event: this.download,
        },
        { title: this.$t("seeFrames"), icon: "mdi-eye", event: this.seeFrames },
      ];
    },
  },
  methods: {
    reserve() {
      const info = {
        unregister: this.value.reserved,
        name: this.value.name,
        venue: this.value.venue,
        location: this.value.location,
        date: `${this.toDateTime(this.value.startdate)}`,
        eventId: this.value.id,
        url: this.value.url,
        startdate: this.value.startdate,
        organizer: this.value.organizer,
        entrance: this.value.entrance,
        description: this.value.description,
        image: this.value.image,
        guestlist: this.value.guestlist,
      }
      this.$emit('reserve', info)
    },
    toDate(timestamp) {
      const d = new Date(timestamp);
      return d.toLocaleDateString();
    },
    toTime(timestamp) {
      const d = new Date(timestamp);
      return d.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },
    toDateTime(timestamp){
      return `${this.toDate(timestamp)}, ${this.toTime(timestamp)}`
    },
    seeFrames() {
      this.framesModal = true;
    },
    download() {
      this.showCertificate = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-work {
  --summary-work-image-size: 60px;
  @media only screen and (min-width: 1024px) {
    --summary-work-image-size: 180px;
  }
  display: grid;
  grid-template-columns: var(--summary-work-image-size) 1fr;
  column-gap: 8px;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  background: var(--color-grey-light);
  @media only screen and (min-width: 1024px) {
    padding: 12px;
    grid-template-columns: var(--summary-work-image-size) 1fr;
    grid-template-rows: auto auto;
  }
  &__link-image {
    @media only screen and (min-width: 1024px) {
      grid-row: 1 / -1;
    }
  }
  &__figure {
    width: var(--summary-work-image-size);
    height: var(--summary-work-image-size);
    margin-right: 12px;
    padding: 4px;
    border: 1px solid var(--color-grey);
    flex-shrink: 0;
    display: flex;
    background-color: var(--color-white);
    @media only screen and (min-width: 1024px) {
      width: var(--summary-work-image-size);
      height: var(--summary-work-image-size);
    }
    img {
      margin: auto;
      width: auto;
      max-height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
  &__header {
    grid-column-start: 2;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-weight: 600;
    width: calc(100% - 10px);
    font-size: var(--font-base-size);
    line-height: 1.2;
    > :first-child {
      color: var(--color-primary);
    }
    > :last-child {
      font-weight: 300;
    }
  }
  &__content {
    grid-column: 1 / -1;
    @media only screen and (min-width: 1024px) {
      grid-column: auto;
      grid-column-start: 2;
    }
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__data {
    text-align: start;
    border-top: 1px solid var(--color-grey);
    padding-top: 4px;
    margin-top: 4px;
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-extra-small);
    font-weight: 200;
    > div {
      margin-right: 12px;
    }
    strong {
      font-weight: 600;
    }
  }
  &__footer {
    border-top: 1px solid var(--color-grey);
    padding-top: 4px;
    margin-top: 4px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__graph {
    padding-right: 5px;
    & > :last-child {
      max-width: 200px;
    }
  }
  &__actions {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
    > * {
      margin-left: 8px;
    }
  }
}
</style>
