<template>
  <div class="summary-page">
    <div v-if="noEvents" class="text-center" height="100%">
      <h1 class="heading-size-3 mt-12 mb-12">{{ $t("eventsNotAvailable") }}</h1>

      <img
        width="35%"
        src="../../assets/events/undraw_partying_re_at7f.svg"
        alt=""
      />
    </div>
    <div v-else class="neo-card">
      <h2 class="summary-page__section-title heading-size-4 d-flex">
        <v-icon color="base" size="30" class="mr-2"
          >mdi-calendar-heart
        </v-icon>
        <div>{{ $t("upcomingEvents") }}</div>
      </h2>
      <div class="summary-works">
        <EventItem v-for="item in events" :key="item.id" :value="item" @reserve="reserve"/>
      </div>
      <v-divider class="mt-10"></v-divider>
      <h2 class="summary-page__section-title heading-size-4 d-flex mt-4">
        <v-icon color="base" size="30" class="mr-2"
          >mdi-calendar-heart
        </v-icon>
        <div>{{ $t("pastEvents") }}</div>
      </h2>
      <div class="summary-works">
        <EventItem v-for="item in pastEvents" :key="item.id" :value="item" @reserve="reserve"/>
      </div>
    </div>
    
  </div>
</template>

<script>
import EventItem from "@/components/Organisms/EventItem";

export default {
  name: "Events",
  components: {
    EventItem,
  },
  props: {
    events: {
      type: Array,
      default: function () {
        return [];
      },
    },
    pastEvents: {
      type: Array,
      default: function () {
        return [];
      },
    },
    noEvents: { type: Boolean, default: true },
  },
  data: () => ({}),
  methods: {
    reserve(info) {
      this.$emit('reserve', info);
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-page {
  height: 100%;
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1500px;
  margin: auto;

  &__header {
    text-align: left;
    width: var(--main-width-container);
    margin: auto;
  }

  &__title {
    h1,
    h2 {
      line-height: 1;
    }
  }

  &__section-title {
    font-weight: 400;
    margin-bottom: 24px;
  }

  &__avatar {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 2px solid var(--color-primary);
    display: flex;
    overflow: hidden;
    img {
      object-position: center;
      object-fit: cover;
    }
  }
}
.summary-works {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
}

.summary-contenttab {
  display: none;
  &--active {
    width: var(--main-width-container);
    margin: auto;
    display: block;
  }
}

// img {
//   margin-top: 7vh;
//   min-width: 280px;
//   max-height: 500px;
// }
</style>
